import React from "react";

export const Envelope = ({ color = "#9B9B9B" }) => (
  <svg
    width="24"
    height="19"
    viewBox="0 0 24 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill={color}
      d="M21.7784 0H2.09856C0.942859 0 0 0.941833 0 2.09856V16.0889C0 17.2417 0.938662 18.1875 2.09856 18.1875H21.7784C22.9312 18.1875 23.8769 17.2488 23.8769 16.0889V2.09856C23.8769 0.94575 22.9383 0 21.7784 0ZM21.4886 1.39904L11.983 10.9047L2.39511 1.39904H21.4886ZM1.39904 15.7992V2.38158L8.13676 9.06153L1.39904 15.7992ZM2.3883 16.7885L9.13027 10.0465L11.4926 12.3886C11.7661 12.6597 12.2074 12.6588 12.4797 12.3864L14.7832 10.083L21.4886 16.7885H2.3883ZM22.4779 15.7992L15.7724 9.09375L22.4779 2.38825V15.7992Z"
    />
  </svg>
);
