import * as React from "react";

function SvgComponent(props) {
  return (
    <svg
      width={60}
      height={60}
      viewBox="0 0 60 60"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <circle cx={30} cy={30} r={30} fill="#FABEBE" />
      <g clipPath="url(#prefix__clip0)" fill="#FF3737">
        <path d="M31.8 44.4h-3.6c-.84 0-1.8-.72-1.8-2.16v-2.52c0-1.2-.6-2.28-1.56-3.12-2.16-1.68-3.24-4.08-3.24-6.72.12-4.56 3.84-8.16 8.28-8.28 2.28 0 4.44.84 6 2.4 1.56 1.56 2.52 3.72 2.52 6 0 2.52-1.08 4.92-3.12 6.48-1.08.84-1.68 2.16-1.68 3.36v2.76c0 .96-.84 1.8-1.8 1.8zM30 22.8c-3.84 0-7.08 3.24-7.2 7.08 0 2.28.96 4.44 2.76 5.76 1.32 1.08 2.04 2.52 2.04 4.08v2.52c0 .24 0 .96.6.96h3.6c.36 0 .6-.24.6-.6v-2.76c0-1.56.84-3.24 2.16-4.32 1.68-1.32 2.64-3.36 2.64-5.52 0-1.92-.72-3.72-2.16-5.16-1.32-1.32-3.12-2.04-5.04-2.04z" />
        <path d="M33 40.8h-6c-.36 0-.6-.24-.6-.6s.24-.6.6-.6h6c.36 0 .6.24.6.6s-.24.6-.6.6zM30 19.2c-.36 0-.6-.24-.6-.6v-2.4c0-.36.24-.6.6-.6s.6.24.6.6v2.4c0 .36-.24.6-.6.6zM38.04 22.56c-.12 0-.36 0-.48-.12a.58.58 0 010-.84l1.68-1.68c.24-.24.6-.24.84 0s.24.6 0 .84l-1.68 1.68s-.24.12-.36.12zM43.8 30.6h-2.4c-.36 0-.6-.24-.6-.6s.24-.6.6-.6h2.4c.36 0 .6.24.6.6s-.24.6-.6.6zM39.72 40.32c-.12 0-.36 0-.48-.12l-1.68-1.68a.58.58 0 010-.84c.24-.24.6-.24.84 0l1.68 1.68c.24.24.24.6 0 .84 0 .12-.12.12-.36.12zM20.28 40.32c-.12 0-.36 0-.48-.12a.58.58 0 010-.84l1.68-1.68c.24-.24.6-.24.84 0s.24.6 0 .84l-1.68 1.68c-.12.12-.24.12-.36.12zM18.6 30.6h-2.4c-.36 0-.6-.24-.6-.6s.24-.6.6-.6h2.4c.36 0 .6.24.6.6s-.24.6-.6.6zM21.96 22.56c-.12 0-.36 0-.48-.12l-1.68-1.8a.58.58 0 010-.84c.24-.24.6-.24.84 0l1.68 1.68c.24.24.24.6 0 .84-.12.12-.24.24-.36.24zM34.8 30.6c-.36 0-.6-.24-.6-.6 0-2.28-1.92-4.2-4.2-4.2-.36 0-.6-.24-.6-.6s.24-.6.6-.6c3 0 5.4 2.4 5.4 5.4 0 .36-.24.6-.6.6z" />
      </g>
      <defs>
        <clipPath id="prefix__clip0">
          <path
            fill="#fff"
            transform="translate(15.6 15.6)"
            d="M0 0h28.8v28.8H0z"
          />
        </clipPath>
      </defs>
    </svg>
  );
}

export default SvgComponent;
