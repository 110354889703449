import { createUseStyles } from "react-jss";
import {
  xsmallDevice,
  smallDevice,
  mediumDevice,
  largeDevice,
  xlargeDevice,
} from "./../../responsiveRules";

export const useStyles = createUseStyles({
  link: {
    textDecoration: 'none',
    color: '#A3A3A3',
  },
  contentRight: {
    display: 'flex',
    ['@media (max-width: 949.99px)']: {
      display: 'none',
    },
  },
  footer: {
    backgroundColor: "black",
    color: "#A3A3A3",
    //minHeight: "15vh",
    opacity: 0.9,
    // maxHeight: 100,
    display: "flex",
    //padding: "3em 5em",
    justifyContent: "space-between",
    alignItems: "center",
    transition: "background-color .2s",
    zIndex: 99,
    //width: "calc(100% - 10em)",
    width: '100%',
    paddingLeft: 10,
    paddingRight: 10,
    boxSizing: 'border-box',
    paddingTop: 5,
    paddingBottom: 5,
  },
});
