import * as React from "react";

function SvgComponent({ color = "#23A5EE", ...props }) {
  return (
    <svg
      width={36}
      height={36}
      viewBox="0 0 36 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <circle cx={18} cy={18} r={18} fill={color} />
      <path d="M19 17v-6.5h-2V17h-6.5v2H17v6.5h2V19h6.5v-2H19z" fill="#fff" />
    </svg>
  );
}

export default SvgComponent;
