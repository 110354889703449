export const Hamburger = ({ color = "white" }) => (
  <svg
    width="24"
    height="19"
    viewBox="0 0 24 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="24" height="3.8" rx="1.9" fill={color} />
    <rect y="7.60001" width="24" height="3.8" rx="1.9" fill={color} />
    <rect y="15.2" width="24" height="3.8" rx="1.9" fill={color} />
  </svg>
);
