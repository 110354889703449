import * as React from "react"
import { useHistory } from 'react-router-dom'
import classNames from "classnames"
import { Toggle } from "./../../Components/Toggle"
import { IsMobile } from "./../../Components/IsMobile"
import { Logo } from "./../../Logo"
import { Hamburger } from "./../../icons/hamburger"
import Close from "./../../icons/close"
import { scrollTo } from "./../../Components/ScrollTo";
import { useStyles } from "./styles"

const { useLayoutEffect, useState, useRef, useEffect } = React

const Header = (props) => {
  const { 
    currentlyActive, 
    navigationPositions, 
    hideItems,
    variant,
  } = props
  const classes = useStyles();
  const headerRef = useRef();
  const [scrollTop, setScrollTop] = useState(0);
  const history = useHistory()

  useLayoutEffect(() => {
    const onScroll = (e) => {
      setScrollTop(e.target.documentElement.scrollTop);
    };
    window.addEventListener("scroll", onScroll);

    return () => window.removeEventListener("scroll", onScroll);
  }, [scrollTop]);

  const goHome = () => {
    history.push('/')
  }


  return (
    <IsMobile>
      {({ isMobile }) => {
        return (
          <Toggle>
            {({ active, toggle }) => {
              return (
                <>
                <header
                  className={classes.header}
                  ref={headerRef}
                  style={{
                    color:
                      scrollTop < window.innerHeight ? "#E5E5E5" : "#232323",
                    backgroundColor:
                      scrollTop < window.innerHeight ? "#232323" : "#E5E5E5",
                    display: "flex",
                    justifyContent: "space-between",
                    flexDirection: isMobile ? "column" : "row",
                  }}
                >
                  <div
                    style={{
                      flexDirection: "row",
                      width: isMobile ? "100%" : "15%",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      paddingTop: isMobile ? 5 : undefined,
                      paddingBottom: isMobile ? 5 : undefined,
                    }}
                    className={isMobile ? classes.navWrapperMobile : undefined}
                  >
                    <Logo
                      fill={
                        scrollTop < window.innerHeight ? "#E5E5E5" : "#232323"
                      }
                      height={75}
                      width={444/206 * 75}
                      onClick={goHome}
                      className={classes.logo}
                    />
                    {isMobile && (
                      <div
                        onClick={toggle}
                        style={{
                          zIndex: 200,
                          position: "relative",
                          paddingRight: 10,
                        }}
                      >
                        {active ? (
                          <Close
                            color={
                              scrollTop < window.innerHeight
                                ? "#E5E5E5"
                                : "#232323"
                            }
                          />
                        ) : (
                          <Hamburger
                            color={
                              scrollTop < window.innerHeight
                                ? "#E5E5E5"
                                : "#232323"
                            }
                          />
                        )}
                      </div>
                    )}
                  </div>
                    <div>
                {(!isMobile || active) && (
                  <Navigation
                    currentlyActive={currentlyActive}
                    navigationPositions={navigationPositions}
                    toggle={toggle}
                    hideItems={hideItems}
                    variant={variant}
                    isMobile={isMobile}
                  />
                )}
                </div>
                </header>
                
                </>
              );
            }}
          </Toggle>
        );
      }}
    </IsMobile>
  );
};

export default Header


const Navigation = (props) => {
  const { 
    currentlyActive, 
    navigationPositions, 
    toggle, 
    hideItems = [], 
    variant,
    isMobile,
  } = props
  const classes = useStyles();
  const history = useHistory()
  
  useEffect(() => {
    switch (window.location.hash){
      case '#about-us':
        document.getElementById('about-us').scrollIntoView(); break;
      case '#our-process':
        document.getElementById('our-process').scrollIntoView(); break;
      case '#typical-project-example':
        document.getElementById('example-project-timeline').scrollIntoView(); break;
      case '#price':
        document.getElementById('price').scrollIntoView(); break;
      case '#testimonials':
        document.getElementById('testimonials').scrollIntoView(); break;
      case '#contact-us':
        document.getElementById('contact-us').scrollIntoView(); break;
      case '#faq':
        document.getElementById('faq').scrollIntoView(); break;
      default:
    }
    
  }, [])

  return (
    <nav className={classes.navigation}>
      <div
        className={classes.navigationLinks}
        style={{
          //backgroundColor:
          //  currentlyActive === "home" ? "#232323" : "rgb(229, 229, 229)",
        }}
      >
        {isMobile ? <span
          onClick={() => {
            //scrollTo(navigationPositions["home"]);
            //toggle();
            history.push('/')
          }}
          className={classNames({
            [classes.navigationMobile]: isMobile,
            [classes.navigationActive]: currentlyActive === "home",
          })}
        >
          Home
        </span> : null}
        {(variant === 'home' || true) ? <>
          <span
            onClick={() => {
              //scrollTo(navigationPositions["home"]);
              //toggle();
              history.push('/about')
            }}
            className={classNames({
              [classes.navigationMobile]: isMobile,
              [classes.navigationActive]: currentlyActive === "about",
            })}
          >
            About
          </span>
          {/*<span
            onClick={() => {
              scrollTo(navigationPositions["services"]);
              toggle();
            }}
            className={
              currentlyActive === "services" ? classes.navigationActive : undefined
            }
          >
            Services
          </span>
          <span
            onClick={() => {
              scrollTo(navigationPositions["ourWork"]);
              toggle();
            }}
            className={
              currentlyActive === "our-work" ? classes.navigationActive : undefined
            }
          >
            Our Work
          </span>*/}
          <span
            onClick={() => {
              /*scrollTo(navigationPositions["contact"]);
              toggle();*/
              history.push('/contact')
            }}
            className={classNames({
              [classes.navigationMobile]: isMobile,
              [classes.navigationActive]: currentlyActive === "contact",
            })}
          >
            Contact Us
          </span>
        </> : <>
          <span
            onClick={() => {
              //scrollTo(navigationPositions["home"]);
              //window.history.pushState({}, '', '#')
              history.push('/home')
              //toggle();
            }}
            className={
              currentlyActive === "home" ? classes.navigationActive : undefined
            }
          >
            Home
          </span>
          <span
            onClick={() => {
              scrollTo(navigationPositions["about"]);
              window.history.pushState({}, '', '#about-us')
              toggle();
            }}
            className={
              currentlyActive === "about" ? classes.navigationActive : undefined
            }
          >
            About
          </span>
          <span
            onClick={() => {
              scrollTo(navigationPositions["process"]);
              window.history.pushState({}, '', '#our-process')
              toggle();
            }}
            className={
              currentlyActive === "process" ? classes.navigationActive : undefined
            }
          >
            Process
          </span>
          <span
            onClick={() => {
              scrollTo(navigationPositions["typicalProject"]);
              window.history.pushState({}, '', '#typical-project-example')
              toggle();
            }}
            className={
              currentlyActive === "typicalProject"
                ? classes.navigationActive
                : undefined
            }
          >
            Typical Project
          </span>
          <span
            onClick={() => {
              scrollTo(navigationPositions["cost"]);
              window.history.pushState({}, '', '#price')
              toggle();
            }}
            className={
              currentlyActive === "cost" ? classes.navigationActive : undefined
            }
          >
            Price
          </span>
          <span
            onClick={() => {
              scrollTo(navigationPositions["testimonials"]);
              window.history.pushState({}, '', '#testimonials')
              toggle();
            }}
            className={
              currentlyActive === "testimonials"
                ? classes.navigationActive
                : undefined
            }
          >
            Testimonals
          </span>
          {hideItems.includes('contact') ? null : <span
            onClick={() => {
              scrollTo(navigationPositions["contact"]);
              window.history.pushState({}, '', '#contact-us')
              toggle();
            }}
            className={
              currentlyActive === "contact" ? classes.navigationActive : undefined
            }
          >
            Contact
          </span>}
          <span
            onClick={() => {
              scrollTo(navigationPositions["faqs"]);
              window.history.pushState({}, '', '#faq')
              toggle();
            }}
            className={
              currentlyActive === "faqs" ? classes.navigationActive : undefined
            }
          >
            FAQs
          </span>
        </>}
      </div>
      {/*<div
        className={classes.navigationSocial}
        style={{
          backgroundColor:
            currentlyActive === "home" ? "#232323" : "rgb(229, 229, 229)",
        }}
      >
        <div
          style={{
            alignSelf: "center",
            width: "40%",
            justifyContent: "space-between",
            display: "flex",
          }}
        >
          <a
            href={"https://instagram.com"}
            target={"_blank"}
            rel={"noreferrer"}
          >
            <Instagram
              color={
                currentlyActive !== "home" ? "#232323" : "rgb(229, 229, 229)"
              }
            />
          </a>
          <a
            href={"https://instagram.com"}
            target={"_blank"}
            rel={"noreferrer"}
          >
            <LinkedIn
              color={
                currentlyActive !== "home" ? "#232323" : "rgb(229, 229, 229)"
              }
            />
          </a>
          <a
            href={"https://github.com/LunaITSolutions"}
            target={"_blank"}
            rel={"noreferrer"}
          >
            <GitHub
              color={
                currentlyActive !== "home" ? "#232323" : "rgb(229, 229, 229)"
              }
            />
          </a>
        </div>
      </div>*/}
      {/*<Close
        color={"#ffffff"}
        className={classes.closeButton}
        onClick={toggle}
      /> */}   
    </nav>
  );
};