import * as React from 'react'
import Content from "./Components/Content"
import { SingleView } from "./Components/SingleView"
import { IsMobile } from "./Components/IsMobile"
import { Planet } from "./Components/Planet"
import { useStyles } from "./styles"

const PrivacyPolicy = props => {

  const classes = useStyles();

  return <div className={classes.app}>
    
    <Content>
      <IsMobile>
        {({ isMobile }) => {
          return <div 
            className={classes.about__wrapper}
            style={isMobile ?  { paddingTop: 100 } : undefined }
          >
            <SingleView
              header={
                "Privacy Policy"
              }
            >
              <div style={{ position: "absolute", top: -60, right: 40 }}>
                <Planet
                  opacity={0.35}
                  color={"blue"}
                  height={"140px"}
                  x={0}
                  y={0}
                  zIndex={-1}
                />
              </div>
              <Planet
                opacity={0.35}
                color={"red"}
                height={"331px"}
                x={-300}
                y={-150}
                zIndex={-1}
              />
              <h2>Who we are</h2>
              <p>A software development company, our website is www.lunait.solutions.</p>

              <h2>What personal data we collect and why we collect it</h2>
              <p>
                We store a cookie to track how well our advertising campaigns are doing. This
                is anonymized, we don't know who is who.
              </p>
            
              <h2>Who we share your data with</h2>
              <p>
                If you reach out to us.
                We may store your contact details in a third-party CRM system, this
                is strictly for customer relationship management and nothing else.
              </p>

              <h2>How long we retain your data</h2>
              <p>
                We keep your contact details while we actively have a business 
                relationship with you, otherwise we'll clear out data periodically.
              </p>

              <h2>What rights you have over your data</h2>
              <p>
                You can contact us and ask us to remove you from our CRM. 
                This does not include any data we are obliged to keep for administrative, 
                legal, or security purposes.
              </p>

              <h2>How we protect your data</h2>
              <p>
                We use a well established CRM service who use security best practices to
                keep your data safe.
              </p>
              <h2>What data breach procedures we have in place</h2>
              <p>
                If your contact details are exposed via our CRM, we will notify you 
                immediately.
              </p>
            </SingleView>
          </div>
        }}
      </IsMobile>
    </Content>
  </div>
}

export default PrivacyPolicy
