import { useState, useRef, useEffect } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import { Link } from 'react-router-dom'
import classNames from 'classnames'

import { SplitView } from "./../../Components/SplitView";
import { Card } from "./../../Components/Card";
import { Button } from "./../../Components/Button";
import { ActiveContainer } from "./../../Components/ActiveContainer";
import { scrollTo } from "./../../Components/ScrollTo";

import { Logo } from "./../../Logo";
import { HousePin } from "./../../icons/house-pin";
import { Envelope } from "./../../icons/envelope";
import { Phone } from "./../../icons/phone";
import { IconGroup } from "./../../icons/icon-group";
import { PaperPlane } from "./../../icons/paper-plane";

import { useStyles as useMainStyles } from "./../../styles"
import Header from './../../scenes/Header'
import Hero from './../../scenes/Hero'
import Quote from './../../Components/Quote'
import { useStyles } from './styles'
import OurProcess from "../OurProcess"
import FAQs from "./../FAQs"
import Testimonials from "../Testimonials";
import Footer from "./../Footer2";
import { logEvent } from './../../util'

const isEurope = Intl.DateTimeFormat()
  .resolvedOptions()
  .timeZone.startsWith("Europe");

function App() {
  const appClasses = useMainStyles();
  const classes = useStyles()
  const [currentlyActive, setCurrentlyActive] = useState("home");
  const [home] = useState(0);
  const [services, setServices] = useState(0);
  const [ourWork, setOurWork] = useState(0);
  const [contact, setContact] = useState(0);
  const [testimonials, setTestimonials] = useState(0);

  useEffect(() => {
    logEvent({
      type: 'page-view/homepage',
    })
  }, [])

  return (
    <div className={appClasses.app}>
      <Header
        currentlyActive={currentlyActive}
        navigationPositions={{ home, services, ourWork, contact }}
        variant="home"
      >

      </Header>
      <Content>
        <ActiveContainer setActive={() => setCurrentlyActive("home")}>
          <Hero>
            <div className={classes.heroSpacer}></div>
            <h1 style={{ fontSize: "2.5em",  fontFamily: "Helvetica Neue" }}>
              Build your fixed price MVP
            </h1>
            <p style={{ fontSize: "1.3em", fontFamily: "Neue Thin" }}>
              We're Luna IT, an experienced tight-knit dev team.
              Work with us to turn your idea into a superb app and/or website.
            </p>
            <Button 
              onClick={() => scrollTo(contact)} 
              label={"Get in touch"} 
              style={{backgroundColor: '#1b86c4'}}
            />
            <div className={classes.quoteContainer}>
              {/*<Quote
                className={classes.quote}
                comment="I would definitely recommend working with Luna IT."
                who="Sherwin - THS"
                profilePic={require('./sherwin-ths.jpeg').default}
              />*/}
              <Quote
                className={classes.quote}
                comment="We couldn't be happier and highly recommend Luna IT."
                who="Thomas - WREA"
                profilePic={"/img/thomas-roberts.jpeg"}
              />
              {/*<Quote
                className={classNames(classes.quote, classes.quote2)}
                comment="Alastair from Luna IT has assisted us...his strengths lie in his diverse and extensive background in development and startups."
                who="Joe - PETspot"
                profilePic={require('./../../assets/joe-petspot.png').default}
              />*/}
              <Quote
                className={classNames(classes.quote, classes.quote2)}
                comment="A quality development team."
                who="Paul - Classic GT"
                profilePic="/img/paul-lupson.jpeg"
              />
            </div>
          </Hero>
        </ActiveContainer>
        <OurProcess />
      </Content>

      <ActiveContainer
        setActive={() => setCurrentlyActive("testimonials")}
        setPosition={setTestimonials}
      >
        <Testimonials />
      </ActiveContainer>
      <ActiveContainer
        setActive={() => setCurrentlyActive("contact")}
        setPosition={setContact}
      >
        <Contact />
      </ActiveContainer>
      <ActiveContainer
        setActive={() => setCurrentlyActive("faqs")}
        //setPosition={setFaqs}
      >
        <FAQs />
      </ActiveContainer>
      <Footer />
    </div>
  );
}

const Content = ({ children }) => {
  const appClasses = useMainStyles();
  const classes = useStyles();
  return <div className={appClasses.content}>{children}</div>;
};



export const Contact = (props) => {

  const { 
    children, 
    style, 
    title = 'Contact Us', 
    formId = 'contact-form', 
  } = props

  const appClasses = useMainStyles();
  const classes = useStyles();
  const recaptchaRef = useRef();
  const [email, setEmail] = useState("");
  const [body, setBody] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const [isBusy, setIsBusy] = useState(false)

  const sendEmail = e => {
    e.preventDefault()
    setShowErrorMessage(false);
    setShowSuccessMessage(false);
    recaptchaRef.current.executeAsync()
    .then(captcha => {
      setIsBusy(true)
  
      return fetch(
        "https://x30w68yrb9.execute-api.eu-central-1.amazonaws.com/api/email",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            email,
            body: `
              Tel: ${phoneNumber}

              Body
              ===
              ${body}
            `,
            captcha,
          }),
        }
      )
    })
    .then((x) => x.json())
    .then((x) => {
      setIsBusy(false)
      if (x.success) {
        setShowSuccessMessage(true);
      } else {
        setShowErrorMessage(true);
      }
    })
    .catch((error) => {
      console.error(error)
      setIsBusy(false)
      setShowErrorMessage(true);
    });
  };

  if (isBusy){
    return <div className={appClasses.contactWrapper} style={style} id="contact-us">
      <div
        style={{
          display: "flex",
          flexDirection: "column",
        }}
      >
        <h2>
          Sending...
        </h2>
      </div>
    </div>
  }

  return (
    <div className={appClasses.contactWrapper} style={style} id="contact-us">
      {showSuccessMessage || showErrorMessage ? (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          {showSuccessMessage
            ? <h2>Thanks for your interest, we'll be in contact shortly</h2>
            : <>
              <h2>We couldn't send your message. Perhaps this form thinks you
              are a bot. Sorry about that.</h2>
              <p>
                Here's your original message for you to copy, please 
                email me (Alastair) directly instead at
                {' '}<a className={classes.link} href="mailto:alastair@lunait.solutions">alastair@lunait.solutions</a>
              </p>
              <pre className={classes.emailContent}>{body}</pre>
            </>}
          <button
            className={appClasses.button}
            onClick={() => {
              setShowSuccessMessage(false);
              setShowErrorMessage(false);
            }}
          >
            Send another message
          </button>
        </div>
      ) : (
        <SplitView header={title}>
          <div className={appClasses.contactText}>
            {children || (
              <>
                <strong>Ready to get started?</strong>
                <p style={{ color: "#A3A3A3" }}>
                  Send us a message briefly outlining your project and
                  we'll get back to you to talk it over.
                </p>
                <p  style={{ color: "#A3A3A3" }}>
                  Alternatively shoot me (Alastair) an email 
                  {' '}<a className={classes.link} href="mailto:alastair@lunait.solutions">alastair@lunait.solutions</a>
                </p>
              </>
            )}
          </div>
          <section className={appClasses.contact}>
            <form 
              onSubmit={sendEmail} 
              className={appClasses.contactFormWrapper}
              id={formId}
            >
              <input
                defaultValue={email}
                placeholder={"Email Address"}
                onChange={(x) => setEmail(x.currentTarget.value)}
                type="email"
                autocorrect="off"
                autocapitalize="none"
              />
              {/*<input
                defaultValue={phoneNumber}
                placeholder={"Contact Number"}
                onChange={(x) => setPhoneNumber(x.currentTarget.value)}
                type="text"
                autocorrect="off"
                autocapitalize="none"
              />*/}
              <textarea
                defaultValue={body}
                placeholder={"Brief project description or message"}
                onChange={(x) => setBody(x.currentTarget.value)}
              ></textarea>
              <div className={appClasses.contactButtons}>
                <button
                  className={appClasses.button}
                  style={{
                    display: "flex",
                    padding: 0,
                    alignItems: "center",
                    width: "50%",
                    cursor: "pointer",
                  }}
                >
                  <div style={{ flexGrow: 1 }}>Send</div>
                  <div style={{ backgroundColor: "#000", padding: "1em" }}>
                    <PaperPlane />
                  </div>
                </button>
                <ReCAPTCHA
                  badge={"inline"}
                  ref={recaptchaRef}
                  size="invisible"
                  sitekey="6Lco9iIaAAAAAMl3NH5sRc9r_e8z0P6pqXNadt-P"
                />
              </div>
            </form>
          </section>
        </SplitView>
      )}
    </div>
  );
};


export default App;
