import * as React from 'react'


const Column = ({ children, style, ...props }) => (
  <div
    style={{ display: "flex", flexDirection: "column", ...style }}
    {...props}
  >
    {children}
  </div>
)

export default Column
