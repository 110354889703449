import React from 'react'
import Header from './../Header'
import Content from '../../Components/Content'
import { SplitView } from '../../Components/SplitView'
import { useStyles } from './styles'
import { SingleView } from "./../../Components/SingleView"
import { Planet } from "./../../Components/Planet"
import { useStyles as usAppStyles } from './../../styles'
import Footer2 from '../Footer2'
import { logEvent } from './../../util'

const { useEffect } = React

const ContactUs = props => {
  const appClasses = usAppStyles()
  const classes = useStyles()

  useEffect(() => {
    logEvent({
      type: 'page-view/contact',
    })
  }, [])

  return <div className={classes.container}>
   
    <Header
      currentlyActive='contact'
      variant="home"
    />
    <div className={classes.content}>
      <div 
          className={appClasses.about__wrapper}
        >
          <SingleView
            header={
              "Contact Us"
            }
          >
            <div style={{ position: "absolute", top: -60, right: 40 }}>
              <Planet
                opacity={0.35}
                color={"blue"}
                height={"140px"}
                x={0}
                y={0}
                zIndex={-1}
              />
            </div>
              <Planet
                opacity={0.35}
                color={"red"}
                height={"331px"}
                x={-300}
                y={-150}
                zIndex={-1}
              />
            <p className={classes.twoColumnContent}>
              
              <div className={classes.contentLeft}>
                <h2 className={classes.title}>Get in touch</h2>
                <p>
                  Please reach out to 
                  {' '}<a className={classes.link} href="mailto:alastair@lunait.solutions">alastair@lunait.solutions</a>.
                </p>
                <p>
                  We may also be able to help with other software development needs.
                  e.g. in the past, we've done bespoke dev, government work and 
                  rescued failing outsourced projects. 
                </p>
                <p>
                  If you think we might be able to help please get in touch.
                </p>
                
              </div>
              <div className={classes.contentRight}>
                <h2 className={classes.title}>Company Information</h2>
                <p>
                  Luna IT
                  <br />
                  Hobart, TAS, Australia
                  <br />
                  ABN: 12 643 738 890
                </p> 
                <p>
                </p>
                
              </div>

            </p>
            
            
            
            
          </SingleView>
        </div>
    </div>
    
    <Footer2 className={classes.footer}/>
  </div>
}

export default ContactUs
