import React from 'react'
import { createUseStyles } from "react-jss";
import QuoteIcon from './../../assets/Quote'
import classNames from 'classnames'


const Quote = props => {
  const { 
    className,
    comment,
    profilePic, 
    who,
  } = props
  const classes = useStyles()
  return <div className={classNames(classes.container, className)}>
    <div className={classes.quoteIconContainer}>
      <QuoteIcon className={classes.quote} />
    </div>
    <span className={classes.content}>
      {comment}
    </span>
    <div className={classes.whoContainer}>
      <img 
        className={classes.profilePic}
        src={profilePic} 
      />
      <span>
        {who}
      </span>
    </div>
  </div>
}

export default Quote


export const useStyles = createUseStyles({
  container: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  profilePic: {
    width: 24,
    height: 24,
    borderRadius: '50%',
    marginBottom: 5,
  },
  quoteIconContainer: {
    width: 25,
    height: 25,
  },
  content: {
    padding: 10,
    textAlign: 'left',
  },
  whoContainer: {
    flexDirection: 'column',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: 10,
    width: 50,
    minWidth: 50,
  }
})
