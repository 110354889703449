import { createUseStyles } from "react-jss";
import {
  xsmallDevice,
  smallDevice,
  mediumDevice,
  largeDevice,
  xlargeDevice,
} from "./../../responsiveRules";

export const useStyles = createUseStyles({
  quoteContainer: {
    paddingTop: 40,
    //position: 'absolute',
    //bottom: '10vh',
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    //left: 0,
    textAlign: 'center',
    alignItems: 'center',
    justifyContent: 'center',
    paddingRight: 20,
    paddingLeft: 20,
    ['@media (max-width: 991.99px)']: {
      flexDirection: 'column',
    },
    ['@media (min-width: 992px)']: {
      position: 'absolute',
      bottom: '10vh',
      left: 0,
    },
    boxSizing: 'border-box',
  },
  quote: {
    boxSizing: 'border-box',
    ['@media (min-width: 992px)']: {
      paddingRight: 50,
      paddingLeft: 50,
      width: 400,
      maxWidth: '100%',
    }
  },
  quote2: {
    ['@media (max-width: 400px)']: {
      display: 'none',
    }
  },
  heroSpacer: {
    display: 'none',
    height: 105,
    ['@media (max-width: 400px)']: {
      display: 'block',
    }
  },
  link: {
    color: '#1e9fe7',
    textDecoration: 'none',
  },
  emailContent: {
    backgroundColor: '#ffefbd',
    marginBottom: 20,
    padding: 20,
  },
})
