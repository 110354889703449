import { useState } from "react";
import { Planet } from "./Components/Planet";
import { Button } from "./Components/Button";
import { ActiveContainer } from "./Components/ActiveContainer";
import Hero from './scenes/Hero'
import AboutUs from './scenes/AboutUs'
import OurProcess from './scenes/OurProcess'
import ProjectTimeline from './scenes/ProjectTimeline'
import Cost from './scenes/Cost'
import Testimonials from "./scenes/Testimonials"
import LetsDiscuss from "./scenes/LetsDiscuss"
import FAQs from "./scenes/FAQs"
import Header from './scenes/Header'
import Footer from "./scenes/Footer"
import { useStyles } from "./styles"
import Content from "./Components/Content"

function App() {
  const classes = useStyles();
  const [currentlyActive, setCurrentlyActive] = useState("home");
  const [home] = useState(0);
  const [about, setAbout] = useState(0);
  const [process, setProcess] = useState(0);
  const [cost, setCost] = useState(0);
  const [testimonials, setTestimonials] = useState(0);
  const [typicalProject, setTypicalProject] = useState(0);
  const [faqs, setFaqs] = useState(0);

  const goToBookAMeeting = () => {
    window.location.href = 'https://calendly.com/alastair-luna/15min'
  }

  return (
    <div className={classes.app}>
      <Header
        currentlyActive={currentlyActive}
        navigationPositions={{
          home,
          about,
          process,
          cost,
          testimonials,
          faqs,
          typicalProject,
        }}
        hideItems={['contact']}
      />
      <Content>
        <ActiveContainer setActive={() => setCurrentlyActive("home")}>
          <Hero>
            <div className={classes.hiddenDesktop}>
              <Planet color={"yellow"} height={"53px"} x={-30} y={150} />
              <Planet color={"blue"} height={"133px"} x={190} y={500} />
              <Planet color={"red"} height={"53px"} x={99} y={620} />
            </div>
            <h1 className={classes.hero__title}>Need to build an MVP?</h1>
            <p className={classes.hero__p}>
              We’ll turn your idea into a quality product, on time and on
              budget.
            </p>
            <Button
              backgroundColor={"#FFCA2D"}
              textColor={"#000"}
              onClick={goToBookAMeeting}
              label={"Book a Meeting"}
              
            />
          </Hero>
        </ActiveContainer>
        <ActiveContainer
          setActive={() => {
            setCurrentlyActive("about")
          }}
          setPosition={setAbout}
        >
          <AboutUs />
        </ActiveContainer>
        <ActiveContainer
          setActive={() => setCurrentlyActive("process")}
          setPosition={setProcess}
        >
          <OurProcess />
        </ActiveContainer>
        <ActiveContainer
          setActive={() => setCurrentlyActive("typicalProject")}
          setPosition={setTypicalProject}
        >
          <ProjectTimeline />
        </ActiveContainer>
      </Content>
      <ActiveContainer
        setActive={() => setCurrentlyActive("cost")}
        setPosition={setCost}
      >
        <Cost />
      </ActiveContainer>
      <ActiveContainer
        setActive={() => setCurrentlyActive("testimonials")}
        setPosition={setTestimonials}
      >
        <Testimonials />
      </ActiveContainer>
      <ActiveContainer>
        <LetsDiscuss
          buttonAction={goToBookAMeeting}
          buttonText="Book a Meeting"
        />
      </ActiveContainer>
      <ActiveContainer
        setActive={() => setCurrentlyActive("faqs")}
        setPosition={setFaqs}
      >
        <FAQs />
      </ActiveContainer>
      <Footer />
    </div>
  );
}

export default App;
