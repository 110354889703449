import * as React from "react";

function SvgComponent(props) {
  return (
    <svg
      width={60}
      height={60}
      viewBox="0 0 60 60"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <circle cx={30} cy={30} r={30} fill="#FFEFBD" />
      <g clipPath="url(#prefix__clip0)">
        <path
          d="M43.605 29.998a.797.797 0 00-.38-.677l-2.764-1.704 2.768-1.723a.797.797 0 00.002-1.352l-12.808-8.025a.797.797 0 00-.846 0L16.769 24.54a.798.798 0 00.002 1.353l2.75 1.71-2.751 1.72a.797.797 0 00.001 1.353l2.75 1.71-2.751 1.72a.797.797 0 000 1.351l12.807 8.025a.797.797 0 00.846 0l12.808-8.025a.797.797 0 00-.005-1.354L40.46 32.4l2.768-1.723a.797.797 0 00.376-.679zm-24.907-4.783L30 18.133l11.303 7.082c-.11.067-10.98 6.833-11.303 7.035l-11.302-7.035zm22.6 9.573L30 41.867l-11.305-7.083 2.333-1.458 8.55 5.322a.797.797 0 00.843 0l8.528-5.307 2.348 1.447zM30 37.033l-11.301-7.034 2.329-1.456 8.55 5.322a.796.796 0 00.843 0l8.528-5.307 2.345 1.445L30 37.033z"
          fill="#FFCE37"
        />
      </g>
      <defs>
        <clipPath id="prefix__clip0">
          <path
            fill="#fff"
            transform="translate(16.395 16.395)"
            d="M0 0h27.209v27.209H0z"
          />
        </clipPath>
      </defs>
    </svg>
  );
}

export default SvgComponent;
