import { useState, useLayoutEffect, useRef } from "react";
import { TweenMax } from "gsap";

function calcNewPosition(
  windowWidth,
  windowHeight,
  mouseLeft,
  mouseTop,
  offsetLeft,
  offsetTop,
  speed
) {
  var x = mouseTop - offsetLeft;
  var y = mouseLeft - offsetTop;

  var newX = x - windowWidth / 2;
  var newY = y - windowHeight / 2;

  const res = {
    x: offsetLeft + (1 - newX * speed),
    y: offsetTop + (1 - newY * speed),
  };

  return res;
}

export const Planet = ({
  x,
  y,
  speed,
  color,
  height,
  children,
  zIndex,
  animate,
  position = "absolute",
  opacity = 1,
}) => {
  const planetRef = useRef();

  const [windowSize, setWindowSize] = useState(window.innerWidth);

  useLayoutEffect(() => {
    const onMouseMove = (e) => {
      const { x: xx, y: yy } = calcNewPosition(
        window.scrollX,
        window.scrollY,
        e.clientY || window.innerHeight,
        e.clientX || window.innerWidth,
        0,//(window.innerWidth / 100) * x.replace("%", ""),
        0,//(windowSize / 100) * y.replace("%", ""),
        speed * 4,
      );

      TweenMax.to(planetRef.current, 1, {
        x: xx,
        y: yy,
      });
    };

    const onResize = () => {
      setWindowSize(window.innerWidth);
    };

    if (animate) {
      window.addEventListener("load", onMouseMove);
      window.addEventListener("mousemove", onMouseMove);
      window.addEventListener("resize", onResize);

      return () => {
        window.removeEventListener("mousemove", onMouseMove);
        window.removeEventListener("load", onMouseMove);
        window.removeEventListener("resize", onResize);
      };
    }
  }, [speed, x, y, windowSize, animate]);

  const background = (color) => {
    switch (color) {
      case "yellow":
        return "radial-gradient(76.75% 76.75% at 70% 23.25%, #FFD85E 0%, #FFC104 100%)";
      case "red":
        return "radial-gradient(76.75% 76.75% at 70% 23.25%, #EB4343 0%, #FF3535 100%)";
      // case "blue":
      default:
        return "radial-gradient(81.25% 81.25% at 67.32% 18.75%, #26AFFC 0%, #1578B1 100%)";
    }
  };

  const style = {
    position,
    borderRadius: "50%",
    opacity,
    background: background(color),
    // This needs to be done as otherwise we end up using a percentage of the
    // height, which leads to a oval instead of a circle
    height:
      height.indexOf("%") !== -1
        ? (windowSize / 100) * height.replace("%", "")
        : height,
    width:
      height.indexOf("%") !== -1
        ? (windowSize / 100) * height.replace("%", "")
        : height,
    top: y,
    left: x,
    zIndex,
    minWidth:
      height.indexOf("%") !== -1
        ? (windowSize / 100) * height.replace("%", "")
        : height,
    justifyContent: "center",
    alignItems: "center",
    display: "flex",
  }
  return (
    <div
      ref={planetRef}
      style={style}
    >
      {children}
    </div>
  );
};
