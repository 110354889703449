
import * as config from './config'

/**
 * Log an event to the server
 */
export const logEvent = function(event){
  fetch(config.EVENT_LOG_URL, {
    method: 'post',
    body: JSON.stringify(event),
    headers: {
      'luna-api-key': config.EVENT_LOG_API_KEY,
    },
  })
}