import React from "react";
import { createUseStyles } from "react-jss";
import { xsmallDevice, smallDevice } from "../responsiveRules";

export const useStyles = createUseStyles({
  SplitViewWrapper: {
    display: "flex",
    position: "relative",
    flexDirection: "column",
    // paddingTop: '14em',
    minHeight: "60vh",
    // marginLeft: '20%',
    // width: '100%',
    // marginRight: '20%',
    // border: '1px solid #000',
  },
  SplitView: {
    display: "flex",
    flexDirection: "column",
    position: "relative",
    justifyContent: "center",
    maxWidth: 1050,
    padding: "0 30px",
    // border: '1px solid #000',
    alignSelf: "center",
    [xsmallDevice]: {
      gridTemplateColumns: "100%",
    },
    [smallDevice]: {
      gridTemplateColumns: "100%",
    },
  },
  SplitViewHeader: {
    color: '#393939',
    textAlign: "center",
    maxWidth: 1200,
    alignSelf: "center",
    paddingLeft: 20,
    paddingRight: 20,
    fontSize: 45,
    [xsmallDevice]: {
      fontSize: 24,
    },
  },
});

export const SingleView = ({ children, header }) => {
  const classes = useStyles();
  return (
    <div className={classes.SplitViewWrapper}>
      {header && <h1 className={classes.SplitViewHeader}>{header}</h1>}
      <div className={classes.SplitView}>{children}</div>
    </div>
  );
};
