import React from 'react'

const Quote = props => {
  return <svg 
    width="25" 
    height="21" 
    viewBox="0 0 25 21" 
    fill="none"
    {...props}
  >
    <path 
      d="M16.03 9.83C16.63 7.19 17.71 5.09 19.27 3.53C20.83 1.91 22.63 0.799997 24.67 0.199997L23.95 3.53C23.11 4.01 22.27 4.67 21.43 5.51C20.65 6.35 20.05 7.67 19.63 9.47L19 12.53H22.06L20.35 20.45H13.78L16.03 9.83ZM2.44 9.83C3.04 7.19 4.12 5.09 5.68 3.53C7.24 1.91 9.04 0.799997 11.08 0.199997L10.36 3.53C9.52 4.01 8.68 4.67 7.84 5.51C7.06 6.35 6.46 7.67 6.04 9.47L5.41 12.53H8.47L6.76 20.45H0.190001L2.44 9.83Z" 
      fill="#E5E5E5"
    />
  </svg>
}

export default Quote