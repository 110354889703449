import { createUseStyles } from "react-jss";
import {
  xsmallDevice,
  smallDevice,
  mediumDevice,
  largeDevice,
  xlargeDevice,
} from "./responsiveRules";

export const useStyles = createUseStyles({
  app: {
    fontFamily: "'Roboto', sans-serif",
    overflow: "hidden",
    [xsmallDevice]: {
      fontSize: ".8rem",
    },
    [smallDevice]: {
      fontSize: ".8rem",
    },
    [mediumDevice]: {
      fontSize: ".8rem",
    },
    [largeDevice]: {
      fontSize: ".8rem",
    },
    [xlargeDevice]: {
      // fontSize: '.8rem'
    },
  },
  center: {
    display: "flex",
    alignSelf: "center",
  },
  hero__title: {
    fontSize: 50,
    [xsmallDevice]: {
      fontSize: 28,
    },
  },
  hero__p: {
    fontSize: 25,
    [xsmallDevice]: {
      fontSize: 15,
    },
  },
  footer: {
    backgroundColor: "black",
    color: "#A3A3A3",
    //minHeight: "15vh",
    opacity: 0.9,
    // maxHeight: 100,
    display: "flex",
    //padding: "3em 5em",
    justifyContent: "space-between",
    alignItems: "center",
    transition: "background-color .2s",
    zIndex: 99,
    //width: "calc(100% - 10em)",
    width: '100%',
    paddingLeft: 10,
    paddingRight: 10,
    boxSizing: 'border-box',
  },
  landing__footer: {
    backgroundColor: "#fff!important",
    color: "#000!important",
    [xsmallDevice]: {
      flexDirection: "column",
    },
  },
  landing__footer__social: {
    display: "none",
    alignSelf: "center",
    // display: "flex",
    justifyContent: "space-evenly",
    width: 100,
    [xsmallDevice]: {
      width: "40%",
      justifyContent: "space-between",
    },
  },
  footerLinkGroup: {
    display: "flex",
    flexDirection: "row",
    flexGrow: "1",
    justifyContent: "space-around",
    fontSize: "1.2em",
    [xsmallDevice]: {
      flexDirection: "column",
      flexGrow: 0,
    },
    [smallDevice]: {
      flexDirection: "column",
      flexGrow: 0,
    },
  },
  logo: {
    fill: "blue",
    stroke: "blue",
  },
  content: {
    backgroundColor: "#e5e5e52e",
  },
  
  SplitViewWrapper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    // paddingTop: '14em',
    minHeight: "60vh",
    justifyContent: "center",
  },
  SplitView: {
    display: "grid",
    gridTemplateColumns: "50% 50%",
    maxWidth: 1200,
    [xsmallDevice]: {
      gridTemplateColumns: "100%",
    },
    [smallDevice]: {
      gridTemplateColumns: "100%",
    },
  },
  contactWrapper: {
    background:
      "linear-gradient(180deg, #E4ECEE 0%, rgba(228, 236, 238, 0) 100%)",
    minHeight: "100vh",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    paddingBottom: "4em",
    paddingLeft: "2em",
    paddingRight: "2em",
    [xsmallDevice]: {
      padding: "3em",
    },
  },
  contact: {
    display: "flex",
    flexDirection: "column",
    "& input, & textarea": {
      margin: "1em 0",
      fontSize: "1.2em",
      borderRadius: 5,
      border: 0,
      fontFamily: "'Roboto', sans-serif",
      padding: "1em",
      color: "grey",
      height: "1em",
      fontWeight: 200,
    },
    "& button": {
      [xsmallDevice]: {
        width: "100%!important",
        marginBottom: 10,
      },
      [mediumDevice]: {
        marginBottom: 10,
      },
    },
    "& textarea": {
      height: 200,
    },
  },
  contactFormWrapper: {
    display: "flex",
    flexDirection: "column",
  },
  contactButtons: {
    display: "flex",
    justifyContent: "space-between",

    [xsmallDevice]: {
      flexDirection: "column",
    },
    [mediumDevice]: {
      flexDirection: "column",
    },
  },
  contactText: {
    margin: "2em",
    fontSize: "1.4em",
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    [xsmallDevice]: {
      margin: 0,
    },
    [smallDevice]: {
      margin: 0,
    },
  },
  hiddenMobile: {
    [xsmallDevice]: {
      display: "none",
    },
    [smallDevice]: {
      display: "flex",
    },
    [mediumDevice]: {
      display: "flex",
    },
    [largeDevice]: {
      display: "flex",
    },
    [xlargeDevice]: {
      display: "flex",
    },
  },
  hiddenDesktop: {
    [xsmallDevice]: {},
    [smallDevice]: {
      display: "none",
    },
    [mediumDevice]: {
      display: "none",
    },
    [largeDevice]: {
      display: "none",
    },
    [xlargeDevice]: {
      display: "none",
    },
  },
  button: {
    background: "#333333",
    textTransform: "uppercase",
    color: "#fff",
    border: 0,
    fontWeight: "bold",
    padding: "1em",
    cursor: 'pointer',
  },
  sliderWrapper: {
    textAlign: "center",
    maxWidth: "60vw",
    marginLeft: "20vw",
    minHeight: "100vh",
    paddingTop: "8em",
    [xsmallDevice]: {
      maxWidth: "98vw",
      marginLeft: "1vw",
    },
    [largeDevice]: {
      maxWidth: "90vw",
      marginLeft: "5vw",
    },
  },
  timeline: {
    backgroundColor: "black",
    minHeight: "100vh",
    alignItems: "center",
    display: "flex",
    flexDirection: "column",
    padding: "120px 0",
    color: "#fff",
    [xsmallDevice]: {
      padding: "20px 0",
    },
    "& > div": {
      width: "80vw",
      maxWidth: 1600,
      [xsmallDevice]: {
        width: "100vw",
      },
    },
  },
  timeline__title__wrapper: {
    alignSelf: "flex-start",
    paddingBottom: 50,
    [xsmallDevice]: {
      padding: "0 20px 20px 20px",
    },
  },
  timeline_p: {
    fontSize: 25,
    width: "50%",
    [xsmallDevice]: {
      width: "100%",
      fontSize: 15,
    },
  },
  cost: {
    backgroundColor: "white",
    width: "80%",
    marginLeft: "10%",
    margin: 152,
    alignSelf: "center",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    [xsmallDevice]: {
      flex: 1,
      flexGrow: 1,
      textAlign: "center",
      marginTop: 80,
      marginBottom: 80,
      padding: 0,
    },
  },
  cost_h1: {
    // margin: 0,
    maxWidth: 831,
    textTransform: "uppercase",
    fontSize: "6em",
    color: "#fff",
    "text-stroke-width": 3,
    "text-stroke-color": "#23A5EE",
    [xsmallDevice]: {
      "text-stroke-width": 1,
      fontSize: "3em",
    },
  },
  cost__header_container: {
    display: "flex",
    flexDirection: "row",
    [xsmallDevice]: {
      flexDirection: "column",
    },
  },
  cost__p: {
    fontSize: 45,
    maxWidth: 900,
    fontWeight: 700,
    lineHeight: "67.5px",
    [xsmallDevice]: {
      fontSize: 24,
      lineHeight: "29px",
      marginTop: 0,
    },
  },
  landing__contact: {
    display: "flex",
    flexDirection: "row",
    maxWidth: 1200,
    [xsmallDevice]: {
      flexDirection: "column",
    },
  },
  faqs: {
    backgroundColor: "black",
    minHeight: "100vh",
    display: "flex",
    justifyContent: "center",
  },
  faqs__content: {
    width: 1000,
    padding: "8em 0",
    [xsmallDevice]: {
      padding: "2em 0 3em 0",
    },
  },
  faq: {
    display: "grid",
    gridTemplateColumns: "12% 83% 5%",
    padding: "5%",
    borderBottom: "1px solid #fff",
    paddingTop: 20,
    paddingBottom: 20,
    position: "relative",
    "&:last-child": {
      borderBottom: "0px solid #fff",
    },
    [xsmallDevice]: {
      gridTemplateColumns: "90%",
      justifyContent: "center",
    },
    cursor: 'pointer',
  },
  faq__title: {
    color: "#fff",
    paddingLeft: 20,
    [xsmallDevice]: {
      fontSize: 54,
    },
  },
  faq__content: {
    color: "#fff",
    fontSize: 25,
    paddingTop: 10,
    [xsmallDevice]: {
      fontSize: 15,
    },
  },
  faq__content__title: {
    fontSize: 24,
  },
  faq__expand: {
    [xsmallDevice]: {
      position: "absolute",
      top: 30,
      right: 20,
    },
  },
  about__wrapper: {
    padding: "8em 0 0 0",
    [xsmallDevice]: {
      padding: "2em 0 0 0",
    },
  },
  our_process__wrapper: {
    padding: "0 0 8em 0",
    [xsmallDevice]: {
      padding: "0 0 2em 0",
    },
  },
  about__title: {
    fontSize: 45,
    marginTop: 120,
    position: "relative",
    [xsmallDevice]: {
      fontSize: 24,
      marginTop: "52px",
    },
  },
  about__content: {
    color: "#A3A3A3",
    fontSize: 25,
    [xsmallDevice]: {
      fontSize: 15,
      lineHeight: "19px",
    },
  },
  landing__contact__wrapper: {
    padding: "10vw",
    position: "relative",
    display: "flex",
    justifyContent: "center",
    maxWidth: 1200,
  },
  landing__contact__title: {
    width: "75%",
    fontSize: 45,
    [xsmallDevice]: {
      fontSize: 30,
    },
  },
  landing__contact__p: {
    color: "#A3A3A3",
    marginTop: 20,
    marginBottom: 20,
    fontSize: 32,
    [xsmallDevice]: {
      fontSize: 15,
    },
  },
  landing__contact__planet: {
    [xsmallDevice]: {
      position: "absolute",
      right: "180px",
    },
  },
  testimonials: {
    backgroundColor: "white",
    display: "flex",
    justifyContent: "center",
    background:
      "linear-gradient(180deg, #E4ECEE 37.5%, rgba(228, 236, 238, 0) 100%)",
    padding: "8em 0",
    [xsmallDevice]: {
      padding: "52px 0 150px 0",
    },
  },
  testimonial__content: {
    color: "#A3A3A3",
    textAlign: "center",
    fontSize: 20,
    minHeight: 150,
    padding: ".5em",
    [xsmallDevice]: {
      padding: 26,
      fontSize: 15,
    },
  },
  link: {
    margin: "1em",
    textDecoration: 'none',
    color: 'inherit',
  },
  footerLinks: {
    alignItems: 'center', 
    justifyContent: 'center', 
    display: 'flex',
  },
  whoContainer: {
    paddingLeft: 10,
    paddingRight: 10,
  },
});
