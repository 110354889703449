import * as React from "react"
import classnames from "classnames"

import { SingleView } from "./../Components/SingleView"
import { Card } from "./../Components/Card"
import { IsMobile } from "./../Components/IsMobile"
import { Planet } from "./../Components/Planet"

import { useStyles } from "./../styles"

const OurProcess = () => {
  const classes = useStyles()
  return <IsMobile>
    {({ isMobile }) => 
      <div 
        className={classes.our_process__wrapper}
        style={isMobile ? { paddingTop: 20 } : undefined}
        id="our-process"
      >
        <SingleView>
            <h2 
              className={classnames([classes.about__title, classes.center])}
              style={{paddingTop: 25}}
            >
              Our Process
            </h2>
            <Card type={"lightbulb"} title={"Chat"} inline>
              Let&apos;s discuss your MVP on a call and really understand your business idea. 
              Arguably this is the most important step, it&apos;s pretty much impossible to
              foresee all future requirements that may come your way, so we need
              to really understand the ins and outs of your idea. We&apos;ll then take
              this information away, digest it and break it down into its
              fundamental building blocks.
            </Card>
            <Card type={"route"} title={"Plan"} inline>
              <div style={{ position: "absolute", top: 0, right: 0 }}>
                <Planet
                  opacity={1}
                  color={"yellow"}
                  height={"380px"}
                  x={-190}
                  y={-140}
                  zIndex={-1}
                />
              </div>
              We&apos;ll architect a solution and come up with a roadmap. 
              We split the total project into, roughly, weekly achievable milestones
              with a focus on getting live fast, once each milestone is live and
              in production we ask for payment for that milestone, so we 
              are continuously delivering value and you're not paying upfront.
              We&apos;ll
              discuss this with you and agree together on a budget and timeline.
            </Card>
            <Card type={"papers"} title={"Do"} inline>
              We&apos;ll get to work, we&apos;ll integrate with your business systems and
              we&apos;ll add you to ours. That way we&apos;ll share our development process
              with 100% transparency and you can provide us with feedback at every
              step of the way. Want to peek in on our code review process? No
              problem.
            </Card>
          </SingleView>
        </div>
      }
    </IsMobile>
}

export default OurProcess
