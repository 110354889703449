import { useState, useLayoutEffect } from "react";

export const IsMobile = ({ children }) => {
  const [windowSize, setWindowSize] = useState(window.innerWidth);

  useLayoutEffect(() => {
    const resize = (e) => {
      setWindowSize(window.innerWidth);
    };

    window.addEventListener("resize", resize);

    return () => window.removeEventListener("resize", resize);
  }, [windowSize]);

  return children({ windowSize, isMobile: windowSize < 950 });
};
