import { createUseStyles } from "react-jss";
import {
  xsmallDevice,
  smallDevice,
  mediumDevice,
  largeDevice,
  xlargeDevice,
} from "./../../responsiveRules";

export const useStyles = createUseStyles({
  
  hero: {
    //backgroundColor: "#232323",
    backgroundColor: '#333333',
    height: "100vh",
    width: "100vw",
    display: "flex",
    alignItems: "center",
    position: "relative",
    overflow: "hidden",
  },
  contentContainer: {
    padding: 20,
    color: "rgb(255, 255, 255)",
    //zIndex: 99,
    maxWidth: 600,
    margin: '0 auto',
    textAlign: 'center',
  },
});
