import * as React from "react"

import { IsMobile } from "./../Components/IsMobile"
import Column from "../Components/Column"
import Content from "../Components/Content"
import { Toggle } from "./../Components/Toggle"
import Collapsed from "./../icons/collapsed"
import Expanded from "./../icons/expanded"
import { useStyles } from "./../styles"

const FAQs = () => {
  const classes = useStyles();
  return (
    <IsMobile>
      {({isMobile}) => 
        <Content 
          className={classes.faqs}
          style={isMobile ? { paddingTop: 50 } : undefined}
        >
          <Column className={classes.faqs__content}>
            <Column>
              <h1 className={classes.faq__title}>FAQ</h1>
              {[
                {
                  title: "What if my requirements change half way through?",
                  content: `
                    Not a problem, we work by assigning story points to all 
                    tasks that make up the entirety of your project, we also 
                    split tasks by "musts", "should haves" and "nice to haves".
                    This leaves us some wiggle room to trade story pointed 
                    tasks to equivalent new tasks of the same value. So your
                    requirements can change and we can keep to the same budget.
                  `.replace(/\s+/g, ' ').trim()
                  ,
                },
                {
                  title: "Do we/you take equity?",
                  content: `
                    Yes we can do, we are happy to offer a split on raw
                    cost + equity to strike a fair, affordable balance.
                    Generally though we would not want anything over 10%, 
                    we're happy to be a partner but we don't want to take away
                    your baby. 
                  `.replace(/\s+/g, ' ').trim(),
                },
                {
                  title: "What if I already have designs?",
                  content:
                    `We'd actively encourage this. By building your designs
                    first it'll iron out your customer flows and how 
                    everything fits together.`.replace(/\s+/g, ' ').trim(),
                },
                {
                  title: "How do I contact you?",
                  content:
                    "You can contact us via support@lunait.solutions, or through the contact form.",
                },
                {
                  title: "What is the main advantage of working with your company?",
                  content: `
                    With years of experience building countless software projects and even our own, 
                    we're a safe bet that you'll end up with a quality product.
                  `.replace(/\s+/g, ' ').trim(),
                },
                {
                  title: "Who pays for bug fixes?",
                  content: `
                    We do, we're confident that we build with a high level of quality, 
                    so throughout the project and up to three months after project completion 
                    we'll fix bugs free of charge.
                  `.replace(/\s+/g, ' ').trim(),
                },
                {
                  title: "Can you get it done really fast?",
                  content: `
                    Yes, is this a rush job? Get in touch and we'll work out a way to help you. 
                    Depending on the project size, we might be able to get it live in a
                    few weeks.
                  `.replace(/\s+/g, ' ').trim(),
                },
                {
                  title: "Who does the work?",
                  content: `
                    We build everything ourselves. We don't farm out any of our 
                    work to offshore dev shops. Typically doing this results in 
                    low quality work and we pride ourselves on building 
                    good products.
                  `.replace(/\s+/g, ' ').trim(),
                },
              ].map((x, i) => (
                <FAQ
                  {...x}
                  index={i + 1}
                  color={["#23A5EE", "#ED4040", "#FFCD33"][i % 3]}
                />
              ))}
            </Column>
          </Column>
        </Content>
      }
    </IsMobile>
  );
};

export default FAQs

const FAQ = ({ index, title, content, color }) => {
  const classes = useStyles();
  return (
    <Toggle>
      {({ active, toggle }) => (
        <div 
          className={classes.faq} 
          onClick={toggle}
          id="faq"
        >
          <strong style={{ color: "rgba(163, 163, 163)", fontSize: 48 }}>
            0{index}
          </strong>
          <div className={classes.faq__content}>
            <strong className={classes.faq__content__title}>{title}</strong>
            {active && <p>{content}</p>}
          </div>
          <div className={classes.faq__expand}>
            {active ? <Collapsed color={color} /> : <Expanded color={color} />}
          </div>
        </div>
      )}
    </Toggle>
  );
};