import * as React from "react";

function SvgComponent(props) {
  return (
    <svg
      width={60}
      height={60}
      viewBox="0 0 60 60"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <circle cx={30} cy={30} r={30} fill="#B0DEF9" />
      <g clipPath="url(#prefix__clip0)" fill="#2FB0FA">
        <path d="M38.694 38.694c-1.24 0-2.294.804-2.67 1.918H19.388a2.941 2.941 0 01-2.938-2.938 2.941 2.941 0 012.938-2.938h5.756A4.742 4.742 0 0029.88 30a4.742 4.742 0 00-4.736-4.737h-5.005a2.83 2.83 0 00-1.77-1.77v-2.187h4.856a.9.9 0 00.9-.899V16.57a.9.9 0 00-.9-.9h-5.756a.9.9 0 00-.9.9v6.923a2.822 2.822 0 00-1.918 2.67 2.821 2.821 0 002.818 2.818c1.24 0 2.294-.805 2.67-1.919h5.005A2.941 2.941 0 0128.08 30a2.941 2.941 0 01-2.937 2.938h-5.756a4.742 4.742 0 00-4.737 4.736 4.742 4.742 0 004.737 4.737h16.636a2.822 2.822 0 002.67 1.919 2.821 2.821 0 002.818-2.818 2.821 2.821 0 00-2.818-2.818zM18.368 17.469h3.958v2.039h-3.957v-2.039zm-.899 9.713a1.02 1.02 0 010-2.038 1.02 1.02 0 010 2.038zm21.225 15.349a1.02 1.02 0 111.02-1.02 1.02 1.02 0 01-1.02 1.02zM38.694 21.426a2.821 2.821 0 00-2.818 2.818 2.821 2.821 0 002.818 2.818 2.821 2.821 0 002.818-2.818 2.821 2.821 0 00-2.818-2.818zm0 3.838a1.02 1.02 0 010-2.039 1.02 1.02 0 010 2.038z" />
        <path d="M38.694 17.589a6.663 6.663 0 00-6.656 6.655 6.62 6.62 0 002.376 5.097l3.478 6.823a.9.9 0 001.603 0l3.478-6.823a6.62 6.62 0 002.376-5.097 6.663 6.663 0 00-6.655-6.655zM41.7 28.058a.899.899 0 00-.243.297l-2.763 5.42-2.763-5.42a.9.9 0 00-.244-.297 4.833 4.833 0 01-1.85-3.814 4.862 4.862 0 014.857-4.856 4.862 4.862 0 014.856 4.856 4.833 4.833 0 01-1.85 3.814z" />
      </g>
      <defs>
        <clipPath id="prefix__clip0">
          <path
            fill="#fff"
            transform="translate(14.651 14.651)"
            d="M0 0h30.698v30.698H0z"
          />
        </clipPath>
      </defs>
    </svg>
  );
}

export default SvgComponent;
