import React from 'react'
import Header from './../Header'
import Content from '../../Components/Content'
import { SplitView } from '../../Components/SplitView'
import { useStyles } from './styles'
import { SingleView } from "./../../Components/SingleView"
import { Planet } from "./../../Components/Planet"
import { useStyles as usAppStyles } from './../../styles'
import Footer from '../Footer2'
import { logEvent } from './../../util'

const { useEffect } = React

const About = props => {
  const appClasses = usAppStyles()
  const classes = useStyles()

  useEffect(() => {
    logEvent({
      type: 'page-view/about',
    })
  }, [])

  return <div className={classes.container}>
   
    <Header
      currentlyActive='about'
      variant="home"
    />
    <div className={classes.content}>
      <div 
          className={appClasses.about__wrapper}
          //style={isMobile ?  { paddingTop: 100 } : undefined }
          id="about-us"
        >
          <SingleView
            header={
              "About Us"
            }
          >
            <div style={{ position: "absolute", top: -60, right: 40 }}>
              <Planet
                opacity={0.35}
                color={"blue"}
                height={"140px"}
                x={0}
                y={0}
                zIndex={-1}
              />
            </div>
              <Planet
                opacity={0.35}
                color={"red"}
                height={"331px"}
                x={-300}
                y={-150}
                zIndex={-1}
              />
            <p className={classes.twoColumnContent}>
              
              <div className={classes.contentLeft}>
                <h2 className={classes.title}>Our founder</h2>
                
                <p>Luna IT was founded by Alastair.</p>

                <p>Alastair has been a software developer for all his
                professional life.</p>

                <p>He started tinkering with PHP and computer games in the late 2000s 
                but since then has worked for many companies and startups helping 
                them scale their tech from humble beginnings to multi-country operating
                businesses.</p>

                <p>He's built and scaled many MVPs and been through the trials and tribulations
                of building quality apps and websites. He's dealt with the pitfalls of scaling
                and knows how to build apps with a solid foundation that will serve hundreds of 
                thousands of users seamlessly as businesses grow.</p>

                <p>This is his passion, and a honed skill, which is why he decided
                to found a company helping many other businesses do the same thing.</p>
              </div>
              <div className={classes.contentRight}>
                <img 
                  src="/img/Alastair.jpg"
                  className={classes.profileImage}
                />
              </div>

            </p>
            
            <h2 className={classes.title} style={{marginBottom: 0}}>Our tech stack</h2>
            <p>
              We build with <a className={classes.link} href="https://nodejs.org/" target="_blank">Node</a> and
              {' '}<a className={classes.link} href="https://www.serverless.com/" target="_blank">Serverless</a> 
              {' '}on <a className={classes.link}  href="https://aws.amazon.com/" target="_blank">AWS</a> and we use
              {' '}<a className={classes.link}  href="https://graphql.org/" target="_blank">GraphQL</a> where appropriate. 
              {' '}For the frontend we generally use <a className={classes.link}  href="https://expo.dev/" target="_blank">Expo</a> 
              {' '}and <a className={classes.link}  href="https://reactjs.org/" target="_blank">React</a>. 
              {' '}We use <a className={classes.link}  href="https://github.com/" target="_blank">Github</a> and 
              {' '}<a className={classes.link}  href="https://github.com/features/actions" target="_blank">Github Actions</a> for continuous integration. 
              {' '}And we like <a className={classes.link}  href="https://firebase.google.com/docs/database" target="_blank">Firebase</a> for realtime functionality.
              {' '}We manage our project on <a className={classes.link}  href="https://www.pivotaltracker.com/" target="_blank">Pivotal Tracker</a> and 
              {' '}<a className={classes.link}  href="https://slack.com/" target="_blank">Slack</a>.
              {' '}That's not the be all and end all, but it's a good idea of what sort of tech we'd 
              {' '}utilise for your project.
            </p>
            
            
          </SingleView>
        </div>
    </div>
    

    <Footer className={classes.footer}/>
  </div>
}

export default About
