import React from 'react'
import { Link } from 'react-router-dom'
import classNames from 'classnames';
import { Logo } from "./../../Logo";
import { Envelope } from "./../../icons/envelope";
import { Phone } from "./../../icons/phone";
import { IconGroup } from "./../../icons/icon-group";
import { useStyles as useMainStyles } from "./../../styles"
import { useStyles } from './styles'

const Footer2 = props => {
  const { className } = props
  const appClasses = useMainStyles();
  const classes = useStyles()
  const isEurope = Intl.DateTimeFormat()
    .resolvedOptions()
    .timeZone.startsWith("Europe");

  return (
    <footer className={classNames(classes.footer, className)}>
      <Logo 
        color={"#E5E5E5"} 
        height="42"
        width={444 / 206 * 42}
        fill="#A3A3A3"
      />
      <div className={classes.contentRight}>
        <IconGroup>
          <Envelope />
          <a className={classes.link} href="mailto:alastair@lunait.solutions">alastair@lunait.solutions</a>
        </IconGroup>
        <div className={appClasses.footerLinks}>
          <Link 
            to="/privacy-policy"
            className={appClasses.link}
          >
            Privacy Policy
          </Link>
        </div>
      </div>
    </footer>
  );
};

export default Footer2