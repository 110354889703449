import React from "react";
import { createUseStyles } from "react-jss";

const useStyles = createUseStyles({
  icon: {
    display: "flex",
    alignItems: "center",
    "& > *": {
      margin: "1em",
    },
  },
});

export const IconGroup = ({ children }) => {
  const classes = useStyles();

  return <div className={classes.icon}>{children}</div>;
};
