import React from "react";
import { createUseStyles } from "react-jss";
import { xsmallDevice, smallDevice } from "../responsiveRules";

export const useStyles = createUseStyles({
  SplitViewWrapper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    // paddingTop: '14em',
    minHeight: "60vh",
    justifyContent: "center",
  },
  SplitView: {
    display: "grid",
    gridTemplateColumns: "50% 50%",
    maxWidth: 1200,
    [xsmallDevice]: {
      gridTemplateColumns: "100%",
    },
    [smallDevice]: {
      gridTemplateColumns: "100%",
    },
  },
});

export const SplitView = ({ children, header }) => {
  const classes = useStyles();
  return (
    <div className={classes.SplitViewWrapper}>
      {header && <h1>{header}</h1>}
      <div className={classes.SplitView}>{children}</div>
    </div>
  );
};
