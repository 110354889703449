import React from "react";

export const PaperPlane = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M19.8289 0.172136C19.6631 0.00635463 19.4145 -0.0446218 19.197 0.0424093L0.368828 7.57357C0.153399 7.65974 0.00914133 7.86489 0.000860091 8.09681C-0.00738208 8.32868 0.121993 8.54357 0.330742 8.64482L7.75526 12.2456L11.3561 19.6701C11.4543 19.8726 11.6594 20.0004 11.8832 20.0004C11.8901 20.0004 11.8971 20.0003 11.9041 20C12.136 19.9918 12.3412 19.8475 12.4273 19.6321L19.9586 0.804088C20.0456 0.586432 19.9946 0.337877 19.8289 0.172136ZM2.03539 8.16911L16.9817 2.19065L8.07432 11.0979L2.03539 8.16911ZM11.8318 17.9655L8.90295 11.9265L17.8104 3.01924L11.8318 17.9655Z"
      fill="white"
    />
  </svg>
);
