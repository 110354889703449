import * as React from "react";

function SvgComponent(props) {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        stroke={props.color}
        d="M14.348 11.999l7.16-7.145a1.672 1.672 0 00-2.365-2.364L12 9.65 4.857 2.49a1.672 1.672 0 10-2.364 2.364L9.653 12l-7.16 7.144a1.666 1.666 0 000 2.364 1.664 1.664 0 002.364 0L12 14.347l7.143 7.16a1.664 1.664 0 002.364 0 1.666 1.666 0 000-2.364l-7.16-7.144z"
        fill={props.color || '#fff'}
      />
    </svg>
  );
}

export default SvgComponent;
