import * as React from 'react'
import { useStyles } from './../styles'

const Content = ({ children, ...props }) => {
  const classes = useStyles();
  return (
    <div className={classes.content} {...props}>
      {children}
    </div>
  )
}

export default Content
