import * as React from 'react'
import Slider from "react-slick"
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./../styles/slick.css";
import Quotes from "./../icons/quotes";
import Content from '../Components/Content'
import Column from '../Components/Column'
import { useStyles } from "./../styles"

const { Fragment } = React


const Testimonials = () => {
  const classes = useStyles();

  var testimonial1 = <Testimonial
    photoSource="/img/joe-petspot.png"
    name={<div className={classes.whoContainer}>
      <strong>Joseph Commisso</strong> &bull; <strong>Founder</strong> &bull; <strong>PETspot</strong>
    </div>}
  >
    “Alastair from Luna IT has assisted us in building an entire
    cross-platform application (App &amp; Web) from the ground up using
    new breakthrough technologies. He has proven to be extremely
    resourceful, and a great critical thinker for problem-solving. He
    often provides innovative solutions to issues/challenges that we
    have faced throughout this project. His strengths also lie in his
    diverse and extensive background in development and startups”
  </Testimonial>
  var testimonial2 = <Testimonial
    photoSource="/img/sherwin-ths.jpeg"
    name={<div className={classes.whoContainer}>
      <strong>Sherwin Zhao</strong> &bull; <strong>Lead Dev</strong> &bull; <strong>Tasmanian Health Service</strong>
    </div>}
  >
    “I think we are fortunate enough to have Ali on board to work on the
    UI/UX for a Statewide project within the Tasmanian State Government.
    As the back-end and API developer in the team, I was the one that
    worked most closely with Ali. From my personal point of view, Ali is
    a fantastic person to work with. This is not just for his superior
    expertise in Node and React JS, but also for his personality of
    being nice and responsive. I am also amazed by his broad knowledge
    coverage which crosses areas like programming, network security, and
    API design. I would definitely recommend working with him.”
  </Testimonial>

  var testimonial3 = <Testimonial
    photoSource="/img/thomas-roberts.jpeg"
    name={<div className={classes.whoContainer}>
      <strong>Thomas Roberts </strong> &bull; <strong>Founder</strong> &bull; <strong>Which Real Estate Agent</strong>
    </div>}
  >
    “We partnered with Luna IT to develop the next generation of our Google 
    Advertising platform. Adopting an agile, but considered approach to 
    tackling this complex task was crucial. Luna IT excelled. Their value 
    add was immediately apparent from the scoping and concept stage right 
    through to implementation and performance optimisation. We couldn't be 
    happier and highly recommend Luna IT.”
  </Testimonial>

  var testimonial4 = <Testimonial
    photoSource="/img/paul-lupson.jpeg"
    name={<div className={classes.whoContainer}>
      <strong>Paul Lupson</strong> &bull; <strong>Founder</strong> &bull; <strong>Classic GT</strong>
    </div>}
  >
    “We've had a great experience working with Luna IT. They're developing the 
    MVP of our classic & sports car marketplace, ClassicGT. Our app is a fairly 
    complicated mix of big data, an admin management dashboard and customer 
    facing classified ads, but Ally & Alan quickly grasped the concept and 
    progressed the development. The guys always go one step beyond and let us 
    know of more optimal or more robust solutions which is a great help when 
    building for scale. A quality development team.”
  </Testimonial>

  return <Content 
    className={classes.testimonials}
    //style={isMobile ? { paddingTop: 100 } : undefined}
    id="testimonials"
  >
    <Column>
      <h1 style={{ alignSelf: "center" }}>Testimonials</h1>
      <Slider
        centerMode
        dots={true}
        arrows={true}
        slidesToShow={window.innerWidth < 800 ? 1 : 3}
        style={{ width: window.innerWidth }}
      >
        {/* We can trim these if the content is too large */}
        
        {testimonial1}
        {testimonial4}
        {testimonial2}
        {testimonial3}
      </Slider>
    </Column>
  </Content>
};

export default Testimonials

const Testimonial = (props) => {

  const { 
    title, 
    children, 
    inline, 
    photoSource,
    name,
  } = props

  const classes = useStyles();

  return (
    <div
      style={{ display: "flex", flexGrow: 1, margin: "1em", minHeight: 468 }}
    >
      <div
        style={{
          justifyContent: "space-between",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          backgroundColor: "#fff",
          padding: "2em 0",
          position: "relative",
        }}
      >
        <div style={{ position: "absolute", top: 33, right: 29 }}>
          <Quotes />
        </div>
        {photoSource ? <img
          src={photoSource}
          style={{ width: 92, height: 92, borderRadius: "50%" }}
          alt="profile"
        /> : null}
        {!inline && <strong className={classes.title}>{title}</strong>}
        <p className={classes.testimonial__content}>{children}</p>
        <div>
          {name}
        </div>
      </div>
    </div>
  );
};