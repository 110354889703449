
import { useState, useRef } from "react";
import { IsMobile } from "./../../Components/IsMobile"
import { Planet } from "./../../Components/Planet";

import { useStyles } from "./styles"

const Hero = ({ children }) => {
  const classes = useStyles();
  return (
    <div className={classes.hero}>
      <Planet
        x={"-40%"}
        y={"-10%"}
        speed={0.003}
        height={"60%"}
        width={"60%"}
        color={"yellow"}
        animate
      />
      <Planet
        x={"80%"}
        y={"3%"}
        speed={0.006}
        height={"15%"}
        width={"15%"}
        color={"blue"}
        animate
      />
      <Planet
        x={"80%"}
        y={"25%"}
        speed={0.004}
        height={"5%"}
        width={"5%"}
        color={"red"}
        animate
      />
      <div
        className={classes.contentContainer}
      >
        {children}
      </div>
    </div>
  );
}

export default Hero