import { createUseStyles } from "react-jss";
import {
  xsmallDevice,
  smallDevice,
  mediumDevice,
  largeDevice,
  xlargeDevice,
} from "./../../responsiveRules";

export const useStyles = createUseStyles({

  header: {
    backgroundColor: "#232323",
    color: "#E5E5E5",
    // height: '15vh',
    opacity: 0.9,
    // maxHeight: 100,
    display: "flex",
    position: "fixed",
    paddingLeft: "3%",
    paddingRight: "3%",
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: "center",
    transition: "background-color .2s",
    zIndex: 99,
    width: "94%",
    fontFamily: "'Roboto', sans-serif",
    //overflow: "hidden",
    fontSize: ".8rem",
    paddingBottom: 10,
    paddingTop: 10,
  },
  navigation: {
    [xsmallDevice]: {
      // paddingTop: 100,
      flexDirection: "column",
      display: "flex",
      flexGrow: 1,
      border: "1px solid white",
      justifyContent: "space-between",
      top: 0,
      left: 0,
      height: "100vh",
      width: "100vw",
      zIndex: 99,
      backgroundColor: '#232323',
      border: 'none',
      position: 'fixed',
    },
    "& span": {
      margin: "1em",
      marginLeft: 0,
      marginRight: 0,
      fontSize: "1.3em",
      padding: "1em",
      opacity: 0.7,
      cursor: "pointer",
      [xsmallDevice]: {
        fontSize: 25,
        margin: 0,
        padding: 16,
        textAlign: "center",
      },
    },
  },
  navigationLinks: {
    display: "flex",
    [xsmallDevice]: {
      height: "90vh",
      marginTop: 87,
      flexDirection: "column",
    },
  },
  navigationActive: {
    fontWeight: "bold",
    opacity: "1!important",
  },

  /*navigationSocial: {
    display: "none",
    [xsmallDevice]: {
      display: "flex",
      justifyContent: "center",
      textAlign: "center",
      width: "100vw",
      paddingBottom: 50,
    },
  },*/

  logo: {
    cursor: 'pointer',
  },
  navWrapperMobile: {
    //backgroundColor: 'red',
  },
  closeButton: {
    position: 'fixed',
    top: 20,
    right: 20,
  },
  navigationMobile: {
    color: 'white',
  },
});
