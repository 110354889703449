import * as React from "react";

function SvgComponent(props) {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M5.404 20L2 17.502c2.01-3.007 3.048-6.175 3.113-9.506V3h5.933v4.51c-.021 2.313-.562 4.626-1.621 6.939-1.038 2.29-2.378 4.14-4.02 5.551zm9.954 0l-3.404-2.498c2.01-3.007 3.048-6.175 3.113-9.506V3H21v4.51c-.022 2.313-.562 4.626-1.621 6.939-1.038 2.29-2.378 4.14-4.02 5.551z"
        fill="#F63B3B"
      />
    </svg>
  );
}

export default SvgComponent;
