import * as React from 'react'
import { 
  BrowserRouter as Router, 
  Switch, 
  Route,
  useLocation, 
} from "react-router-dom"
import Homepage from './scenes/Homepage'
import Landing from "./Landing";
import LandingGetFreeQuoteVariant from './LandingGetFreeQuoteVariant'
import LandingBookAMeetingVariant from './LandingBookAMeetingVariant'
import PrivacyPolicy from './PrivacyPolicy'
import About from './scenes/About'
import ContactUs from './scenes/ContactUs'

const { useEffect } = React

const App = () => {
  return <Router>
    <Routes />
  </Router>
}

const Routes = () => {

  const { pathname } = useLocation()

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [pathname])

  return <Switch>
    <Route path={"/privacy-policy"}>
      <PrivacyPolicy />
    </Route>
    {/*<Route path={"/need-to-build-an-mvp"}>
      <Landing />
    </Route>
    <Route path={"/need-to-build-an-mvp-get-a-free-quote"}>
      <LandingGetFreeQuoteVariant />
    </Route>
    <Route path={"/need-to-build-an-mvp-book-a-meeting"}>
      <LandingBookAMeetingVariant />
    </Route>*/}
    <Route path="/" exact>
      <Homepage />
    </Route>
    <Route path="/about">
      <About />
    </Route>
    <Route path="/contact">
      <ContactUs />
    </Route>
  </Switch>
}

export default App;
