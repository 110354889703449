import React, { useLayoutEffect, useRef } from "react";

export const ActiveContainer = ({
  setPosition = () => {},
  setActive = () => {},
  children,
}) => {
  const containerRef = useRef();

  useLayoutEffect(() => {
    setPosition(containerRef.current.offsetTop);
    const scroll = () => {
      if (
        window.scrollY + 15 > containerRef.current.offsetTop &&
        window.scrollY <
          containerRef.current.offsetTop +
            containerRef.current.getBoundingClientRect().height
      ) {
        setActive();
      }
    };
    window.addEventListener("scroll", scroll);
    return () => window.removeEventListener("scroll", scroll);
  }, [setActive, setPosition]);

  return (
    <div ref={containerRef} style={{ position: "relative" }}>
      {children}
    </div>
  );
};
