import React from "react";
import { createUseStyles } from "react-jss";

const useStyles = createUseStyles({
  button: {
    background: "#333333",
    textTransform: "uppercase",
    color: "#fff",
    border: 0,
    fontWeight: "bold",
    padding: "1em 3em",
    cursor: "pointer",
    borderRadius: 2,
  },
});

export const Button = (props) => {
  const { onClick, label, backgroundColor, textColor, style } = props
  const classes = useStyles();
  return (
    <button
      className={classes.button}
      style={Object.assign({ background: backgroundColor, color: textColor }, style)}
      onClick={onClick}
    >
      {label}
    </button>
  );
};
