import { createUseStyles } from "react-jss";
import {
  xsmallDevice,
  smallDevice,
  mediumDevice,
  largeDevice,
  xlargeDevice,
} from "./../../responsiveRules";

export const useStyles = createUseStyles({
  container: {
    fontFamily: "'Roboto', sans-serif",
    overflow: "hidden",
    fontSize: 20,
    minHeight: '100vh',
    position: 'relative',
    paddingBottom: 100,
  },
  about__content: {
    
  },
  twoColumnContent: {
    display: 'flex',
    flexDirection: 'row',
    ['@media (max-width: 991.98px)']: {
      flexDirection: 'column',
    }
  },
  content: {
    color: "#A3A3A3",
    fontSize: 20,
    [xsmallDevice]: {
      fontSize: 15,
      lineHeight: "19px",
    },
    ['@media (max-width: 949.99px)']: {
      paddingTop: 100,
      //paddingBottom: 100,
    },
  },
  contentLeft: {
    paddingRight: 50,
    flex: 1,
  },
  contentRight: {
    maxWidth: '100%',
    ['@media (min-width: 700px)']: {
      width: 400,
    },
  },
  profileImage: {
    ['@media (min-width: 700px)']: {
      width: 400,
    },
    maxWidth: '100%',
    borderRadius: 3,
  },
  title: {
    color: '#393939',
  },
  link: {
    color: '#1e9fe7',
    textDecoration: 'none',
  },
  footer: {
    position: 'absolute',
    bottom: 0,
    left: 0,
  },
});
