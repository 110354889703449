import * as React from "react";

function SvgComponent({ color = "#23A5EE", ...props }) {
  return (
    <svg
      width={36}
      height={36}
      viewBox="0 0 36 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <circle cx={18} cy={18} r={17} stroke={color} strokeWidth={2} />
      <path d="M26 19.31H10v-2.62h16v2.62z" fill={color} />
    </svg>
  );
}

export default SvgComponent;
